<template>
    <div >
        <v-toolbar style="background:#cad9e5;">
            <v-row>
                <v-btn
                    tile
                    color=""
                    style="margin-right:1%;"
                    @click="getDatas"
                    v-bind:disabled="this.cancelDisabled==false"
                >
                    <v-icon left>
                        mdi-magnify
                    </v-icon>
                    검색
                </v-btn>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    v-bind:disabled="this.cancelDisabled==false"
                    @click="addunitall"
                >
                    <v-icon left>
                        mdi-plus-circle
                    </v-icon>
                    품목 추가
                </v-btn>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;"
                    
                    @click="dialogCancel=true"
                    v-bind:disabled="this.cancelDisabled==true"
                >
                    <v-icon left>
                        mdi-minus-circle
                    </v-icon>
                    품목  삭제
                </v-btn>
                <v-spacer></v-spacer>
           
                <v-btn
                    tile
                    color="success"
                    style="margin-right:1%;"
                    @click="wearingExcelUpload"
                >
                    <v-icon left>
                        mdi-upload
                    </v-icon>
                    업로드
                </v-btn>
                <div hidden>
                <v-btn
                    tile
                    color="primary"
                    style="margin-right:1%;"
                    @click="wearingExcelDownload"
                >
                    <v-icon left>
                        mdi-download
                    </v-icon>
                    BIN별 품목 설정 내역
                </v-btn>
            </div>
                <v-btn
                    tile
                    color="error"
                    style="margin-right:1%;"
                    @click="wearingExcelTemplate"
                >
                    <v-icon left>
                        mdi-file-excel
                    </v-icon>
                    템플릿
                </v-btn>                
            
            </v-row>
        </v-toolbar>

        <v-toolbar style="background:#cad9e5;">
             <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    id="wearing-bin_code"
                    label="위치정보"
                    @change="value => onTextChangne('bin_code', value)"
                    @input="onTextChangne2('bin_code', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    v-model="i_code"
                    id="wearing-item_code"
                    label="품번"
                    v-on:i_code_update="onTextChangne2('item_code', $event)"
                    @change="value => onTextChangne('item_code', value)"
                    @input="onTextChangne2('item_code', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                    ref="itemcode_val"
                ></v-text-field>
            </v-toolbar-items>
            <v-toolbar-items style="margin-right:1%; margin-top:3%;">
                <v-text-field
                    id="wearing-item_name"
                    label="품명"
                    @change="value => onTextChangne('item_name', value)"
                    @input="onTextChangne2('item_name', $event)"
                    @keyup="getDatas"
                    @keydown="disabledSelectAll"
                ></v-text-field>
            </v-toolbar-items>
         </v-toolbar>
        <v-data-table 
            v-model="selected"
            :headers="visibleHeaders"
            :items="datas" 
            
            class="elevation-1"
            :show-select="true"
            
            @click:col[0]="onClickCol"
            @click:row="onClickRow"
            height="100%"
            fixed-header
            dense
            :items-per-page="perPage"
            :footer-props="footerProps"
            :page.sync="page"
            :server-items-length="dataCounts"
            :options.sync="options"
            
            ref="dataTable" 
            group-by="bin_code"
        >
   
        <template v-slot:group.header="{ group, headers, toggle, isOpen}">
            <td :colspan="headers.length"> 
                    <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
                        <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                        <v-icon v-else>mdi-chevron-down</v-icon>
                    </v-btn>                                  
                    <input type="checkbox" id="itembid" :value=group style="width:16px;height:16px;vertical-align:middle;"> {{ group }} 
            </td>
        </template>

        </v-data-table>
       
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-dialog
            v-model="dialog_setLocationItem"
            width='80%'
            height="45%"
            max-width="1200px"
            style="height: 45%;"
            scrollable      
            @change="load_container"                                                       
            >
            <div style="width:100%; height:100%; display:flex; background:white;">
                <div id="form" style="width:100%; height:650px;">
                    <v-card>
                        <v-card-title>
                            <span class="text-h4">Location 품목추가</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            ref="add_bin_code"
                                            v-model="add_bin_code"
                                            label="위치정보"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                 
                                    <v-col cols="12">

                                        <v-autocomplete
                                            id="itemsid"
                                            v-model="search_item"
                                            auto-selct-first
                                            autofocus
                                            ref="autoinput"

  
                                            label="검색"
                                            clear-icon="mdi-close-circle"
                                            :append-icon="'mdi-magnify'"
                                            type="text"
                                            :items="searchItemList"
                                            :search-input.sync="searchAdd"
                                            hide-details
                                            item-text="search_name"
                                            item-value="search_name"
                                            return-object
                                            @mousedown="searchAdd_void"
                                            @change="clickAddItemCode" 
                                            @click="searchAdd_void, $refs.add_item_code.focus()"
                                            v-on:keyup.enter="searchAdd_void,$refs.add_item_code.focus()"
                                        ></v-autocomplete>     
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            ref="add_item_code"
                                            v-model="add_item_code"
                                            label="품번"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            ref="add_item_name"
                                            v-model="add_item_name"
                                            label="품명"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            ref="add_specification"
                                            v-model="add_specification"
                                            label="규격"
                                            readonly
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            ref="add_detail_info"
                                            v-model="add_detail_info"
                                            label="상세사양"
                                            readonly
                                        ></v-text-field>
                                    </v-col>

                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="primary"
                                            @click="execUnitSetAll"
                                            ref="instockbtn"
                                        >
                                            품목 추가
                                        </v-btn>
                                        <!--
                                        <v-btn
                                            tile
                                            color="primary"
                                            @click="execUnitSet"
                                            ref="instockbtn"
                                        >
                                            품목 추가
                                        </v-btn>
                                        -->
                                    </v-col>
                                    <v-col cols="6" align="center">
                                        <v-btn
                                            tile
                                            color="error"
                                            @click="() => dialog_setLocationItem = false"
                                    >   
                                            취소
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </div>
                <div id="cards" style="width:30%; height:650px; overflow: scroll;" hidden>
                    <v-card 
                        v-for="(card, index) in cards" 
                        :key="index" 
                        style="margin-bottom:1%; background:#c6c6f8;"
                        @click="clickCardBin(card)"    
                    >
                        <v-card-title>{{ card.bin_code }}  - {{ card.lotno }}</v-card-title>
                        <v-card-text>{{ card.quan + ' ' + card.unit_code }}</v-card-text>
                    </v-card>
                </div>
            </div>
        </v-dialog>

        <v-dialog
            v-model="dialogCancel"
            width="500px"
            max-width="500px"
        >
            <v-card style="height:180px;">
                <v-card-title>Bin 품목 삭제</v-card-title>
                <v-card-text>선택하신 품목을 Bin에서 삭제 하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;">
                    <v-btn
                        color="white"
                        style="backgroundColor:green;"
                        text
                        @click="delLocationItem"
                    >확인</v-btn>
                    <v-btn
                        color="white"
                        style="backgroundColor:red;"
                        text
                        @click="dialogCancel = false"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>
    </div>
</template>

<script>
import axios from 'axios'
import { emit } from 'process';

export default {
  name: "LocationMan",
  components: {
    
  },
  data() {
    return {
        autoUpdate: true,
        headers: [
            { value:'unique_id', text:'unique_id', width:'1%', align:'start', sortable: true, class: 'cell-header',visible: false},
            { value:'bin_code', text:'BIN CODE', width:'4%', align:'start', sortable: true, class: 'cell-header',visible: true},
            { value:'bin_name', text:'BIN NAME', width:'5%', align:'start', sortable: true, class: 'cell-header',visible: false},
            { value:'item_code', text:'품번', width:'35%', align:'start', sortable: false, class: 'cell-header',},
            { value:'item_name', text:'품명', width:'55%', align:'start', sortable: false,class: 'cell-header',},
            { value:'uid_bin', text:'uid_bin', width:'5%', align:'start', sortable: false,class: 'cell-header',visible: false},

        ],
        i_code:'',
        datas:[],
        dataCounts:0,
        perPage:-1,
        page:1,
        footerProps: { 'items-per-page-options': [50,100, -1] },
        options:{},
        params:{},

        lotno:null,

        sortBy: ['item_code','item_name'],
        sortDesc: [true,false],

        overlay: false,
        dialog_setLocationItem:false,
        dialogCancel:false,

        search_item:null,
        value:'TP',
        item_code:'TP',

        orderBy:null,
        order_desc:null,

        searchAdd:null,
        searchItemList:[],
 
        searchBinAdd:null,
        addBinCodes:[],

        add_item_uid:null,
        add_item_code:null,
        add_item_name:null,
        add_specification:null,
        add_detail_info:null,
        add_quan:null,
        add_bin_code:null,

        cards: [],
        selected:[],
        selectedCard:null,
        selectedItem:null,
        
        snack:false,
        snackColor:'',
        snackText:'',

        wearing_date_menu:false,
        date_range:'',
        start_date:'',
        end_date:'',

        inputStart:false,
        inputEnd:false,

        bgcolor_flag:0,
        cancelDisabled:true,
        remark:null,
        bin_code:null,
        item_name:null,
        uid_bin:null,
        uid_item:null
    };
  },

  mounted() {
    //this.init();

    this.$nextTick(() => {
    });
  },

  watch: {

      selected() {
        if(this.selected.length>0) {
            this.cancelDisabled = false
        } else {
            this.cancelDisabled = true
        }
      },
      getContextPath(){
            var hostIndex = location.href.indexOf( location.host ) + location.host.length;
            var contextPath = location.href.substring( hostIndex, location.href.indexOf('/', hostIndex + 1) );
            return contextPath;
      },
      searchAdd(val) {

        if(val == null || val.length < 1) return;
            var sresult='';
            if(val!='') {
                const str = val;
                const result = str.split("/");
                sresult = result[0]; 
            }
            else{
                sresult = '';
            }

        if(val == null || val.length < 1) return;

            var url = this.$vBACKEND_URL + '/item.do?method=readItem'; 
            var pObj = {
                uid_company:this.$store.getters.getUidCompany,
                search_item:sresult    
            }
            var params = '';
            for(var key in pObj) {
                params += '&' + key + '=' + pObj[key]
            }

            url += params;

            fetch(url)
                .then(res => res.clone().json())
                .then(res => {
                    this.searchItemList = res.result
                    this.item_uids = res.result[0].unique_id
                    this.clickAddItemCode(res.result[0]) 
                })
                .catch(err => {
                    console.log(err)
                })
      },
      searchBinAdd(val) {
          if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            bin_code:val
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.addBinCodes = res.result
            })
            .catch(err => {
                console.log(err)
            })
      },

      wearing_date_menu() {
          if(this.wearing_date_menu) {
              this.inputStart = false;
              this.inputEnd = false;
          }
      },
      
     options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;
        
        if(val.sortBy[0]){
            this.orderBy = "&orderBy=" + val.sortBy[0];
            this.order_Desc = "&isAsc=" +  val.sortDesc[0];
        }
        else{
            this.orderBy=null;
            this.order_Desc=null;
        }
        this.getDatas();
     }
  },
  computed: {
    visibleHeaders() {
      return this.headers.filter(header => header.visible !== false);
    }
  },
  methods: {
    init() {        
        this.getDatas()
    },
    onInput(item){
    },

    sortFunction(){
    },

    clearCheckboxes() {
      this.selected = this.selected.filter(item => false); 
    },

    searchAdd_void() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            search_item:''
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.searchItemList = res.result
            })
            .catch(err => {
                console.log(err)
            })
    },

    getDatas() {
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var url = this.$vBACKEND_URL + '/stock.do?method=readBinItem';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=bin_code";
            url += this.order_Desc="&isAsc=true";            
        }

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count; 
              //console.log(datas)             
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            
        })
    },

    onClickCol(record, row) {
        if( row.item.uid_bin == 0){
            this.onClickRow(record,row);
        }
        else{
            const isSelected = row.isSelected;
            if(isSelected) {
                row.select(false);
            } else {
                row.select(true);
            }
        }
    },

    search_1() {
      const query = this.$refs.add_bin_code.internalValue;
      if (query) {
        this.$refs.autocomplete.search = query;
      }
    },

    onClickRow(record, row) {
        // 품목추가
        //this.resetAddForm();
        //this.dialog_setLocationItem=true;
 
        //this.lotno = row.item.lotno;
        //this.uid_company = this.$store.getters.getUidCompany;
        //this.add_bin_code = row.item.bin_code;
        //console.log(row.item.bin_code) ;
    },

    disabledSelectAll(){
        const selectedItems = this.$refs.dataTable.selection;

        for(var key in selectedItems) {
            selectedItems[key] = false;
            key = null;
        }
        this.selected = [];
    },

    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        }else {
            this.params[key]="";
            this.params[key] = val;
        }
    },

    onTextChangne2(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
        }else {
            this.params[key]="";
            this.params[key] = val;
        }
    },

    findIndex (target) {
      return [].findIndex.call(this.elements, e => e === target)
    },
    moveFocus (index) {
      if (this.elements[index]) {
        this.elements[index].focus()
      }
    },
    moveNext (event) {
      const index = this.findIndex(event.target)
      this.moveFocus(index + 1)
    },
    movePrev (event) {
      const index = this.findIndex(event.target)
      this.moveFocus(index - 1)
    },
    next(e) {
      e.target?.nextSibling?.focus();
    },

    wearingExcelUpload() {
        let input = document.createElement('input')

        input.id = 'excel'
        input.type = 'file'
        input.accept = 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        input.multiple = true

        input.click();

        var url = this.$vBACKEND_URL + '/template.do?method=execLocationitemExcel'
        var me = this

        // Event
        input.onchange = function() {
            me.overlay = true
            const formData = new FormData()
            formData.append('file', this.files[0])
            formData.append('uid_company', me.$store.getters.getUidCompany)
            formData.append('user_uid', me.$store.getters.getUserUid)
            formData.append('user_id', me.$store.getters.getUserId)
            formData.append('user_name', me.$store.getters.getUserName)

            axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                        //console.log(res)
                        me.snack = true;
                        me.snackColor = '#60C5F1';
                        me.snackText = '성공'

                        me.getDatas();
                    }).catch(err => {
                        console.log(err)
                        me.snack = true;
                        me.snackColor = "red";
                        me.snackText = '실패'
                    }).finally(() => {
                        me.overlay = false
                    })
        }
    },

    wearingExcelDownload() {
        this.overlay = true

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationMan';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          gubun:'IN',
          apiType:'ALL',
          orderBy:'history.his_date desc',
          command:'EXCEL'
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;
            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath 
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    wearingExcelTemplate() {
        this.overlay = true
        var url = this.$vBACKEND_URL + '/template.do?method=locationitemExcelTemplateDown'; 
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
        }
        var params = '';
        for(var key in obj) {
        params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;

            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath 
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    },

    clickCardBin(card) {
        this.searchBinAddFn(card.bin_code)
    },

    searchBinAddFn(val) {
        if(val == null || val.length < 1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readBin';
        var pObj = {
            uid_company:this.$store.getters.getUidCompany,
            bin_code:val
        }
        var params = '';
        for(var key in pObj) {
            params += '&' + key + '=' + pObj[key]
        }

        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                this.addBinCodes = res.result
                this.add_bin_code = this.addBinCodes[0].bin_code
                //console.log(this.addBinCodes)
            })
            .catch(err => {
                console.log(err)
            })
    },

    clickAddItemCode(o) {
        if(o!=null) {
            this.selectedItem = o;
            this.add_item_uid = o.unique_id
            this.add_item_code = o.item_code
            this.add_item_name = o.item_name
            this.add_specification = o.specification
            this.add_detail_info = o.detail_info

            this.getAddCard(o);
        }
    },

    load_container(){
    },

    clickAddBinCode(o) {
        if(o!=null) {
            this.add_bin_code = o.bin_code
        }
    },

    getAddCard(o) {
        if(o==null || o.length<1) return;

        var url = this.$vBACKEND_URL + '/stock.do?method=readLocationByUidItem';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            uid_item:this.add_item_uid, 
        }

        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' +  obj[key] 
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const cards = res.result;
            this.cards = cards;
        })
        .catch(err => {
            console.log(err)
        })
    },

    execUnitSet() {
        if(this.add_item_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '품번을 확인해주세요'
            return;
        }
        if(this.add_bin_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '위치정보를 확인해주세요'
            return;
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execWearingBinCodeB';

        var obj = {
            set_lotno:this.lotno,
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            item_uids:this.add_item_uid,
            item_quans:0,
            bin_code:this.add_bin_code,
            remark:this.add_bin_code
        }
        obj.item_uids = this.item_uids;

        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                let result = res.result
                if(result) {
                    this.snack = true;
                    this.snackColor = '#60C5F1';
                    this.snackText = '품목추가 성공'
                } else {
                    this.snack = true;
                    this.snackColor = "red";
                    this.snackText = '위치정보를 확인해주세요'
                }

                this.getDatas()
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => this.resetAddForm())
    },
    execUnitSetAll() {
        // 품목추가 촏차ㅠㅐㅌ
        if(this.add_item_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '품번을 확인해주세요'
            return;
        }
        if(this.add_bin_code==null) {
            this.snack = true;
            this.snackColor = "red";
            this.snackText = '위치정보를 확인해주세요'
            return;
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=execWearingBinCodeBAll';

        var obj = {
            set_lotno:this.lotno,
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            item_uids:this.add_item_uid,
            item_quans:0,
            bin_code:this.add_bin_code,
            remark:this.add_bin_code
        }
        obj.item_uids = this.item_uids;
        var bind_uids = [];
        var chkBin   =   document.querySelectorAll("#itembid");
        for(var j=0; j<chkBin.length; j++){
            if(chkBin.item(j).type == "checkbox" && chkBin.item(j).checked){
                const bind_uid =   chkBin.item(j).value;
                bind_uids.push(bind_uid);                
            }
        }
       
        var me = this
        const formData = new FormData();
        for(var key in obj) {
            formData.append(key, obj[key]);            
        }
        formData.append("bind_id_list", bind_uids); 
        axios.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {                        
                        //console.log(res);
                        let result = res.data.result
                        //console.log(result);
                        if(result) {
                            this.snack = true;
                            this.snackColor = '#60C5F1';
                            this.snackText = '품목추가 성공'
                        } else {
                            this.snack = true;
                            this.snackColor = "red";
                            this.snackText = '위치정보를 확인해주세요'
                        }

                        this.getDatas();
                        this.dialog_setLocationItem=false;
                        this.resetBinchk();
                    }).catch(err => {
                        console.log(err)
                    }).finally(() => {
                        this.resetAddForm()
                    })
       
    },
    resetAddForm() {
        this.lotno = null;
        this.add_item_uid = null
        this.search_item = null
        this.add_item_code = null
        this.add_item_name = null
        this.add_specification = null
        this.add_detail_info = null
        this.add_quan = null
        this.cards = null
        this.selectedCard = null
        this.selectedItem = null
        this.cards = [];
    },
    resetBinchk() {
        var chkBin   =   document.querySelectorAll("#itembid");
        for(var j=0; j<chkBin.length; j++){
            chkBin.item(j).checked  =   false;
        }
    },
    input_start() {
        if(this.inputEnd) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.wearing_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputStart = true;
    },

    input_end() {
        if(this.inputStart) {
            this.date_range = this.start_date + " ~ " + this.end_date;
            this.wearing_date_menu = false;
            this.setDateRangeParam();
        }
        this.inputEnd = true;
    },

    setDateRangeParam() {
        if(this.start_date!=null && this.start_date.length>0 &&
            this.end_date!=null && this.end_date.length>0) {
            this.params['his_s_date'] = this.start_date;
            this.params['his_e_date'] = this.end_date;
        }
    },

    refreshDateRange() {
        this.start_date = '';
        this.end_date = '';
        this.date_range = '';
        this.params['his_s_date'] = '';
        this.params['his_e_date'] = '';
    },

    cancelWearing() {
        this.overlay = true

        var uids = [];
        for(var i=0; i<this.selected.length; i++) {
            const select = this.selected[i]
            const uid = select.id;
            uids.push(uid)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=cancelInOut'
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        params += '&' + 'unique_id_list=' + uids
        url += params

        fetch(url)
        .then(res => {
            this.snack = true;
            this.snackColor = 'green';
            this.snackText = '성공'

            this.getDatas()
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
        })
        .finally(() => {
            this.dialogCancel = false
            this.overlay = false
            this.cancelDisabled = true
        })

    },
    delLocationItem() {
        this.overlay = true
        
        var uids = [];
        for(var i=0; i<this.selected.length; i++) {
            const select = this.selected[i]
            const uid = select.id;
            uids.push(uid)
        }

        var url = this.$vBACKEND_URL + '/stock.do?method=delLocationItem' 
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
        }
        var params = '';
        for(var key in obj) {
            params += '&' + key + '=' + obj[key]
        }
        params += '&' + 'unique_id_list=' + uids
        url += params
        //console.log(url);
        //return;
        fetch(url)
        .then(res => {
            this.snack = true;
            this.snackColor = 'green';
            this.snackText = '성공'

            this.getDatas()
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
        })
        .finally(() => {
            this.dialogCancel = false
            this.overlay = false
            this.cancelDisabled = true
        })

        this.clearCheckboxes();

    },
    

    addunitall() {
        // 품목추가

        var chkBin   =   document.querySelectorAll("#itembid");
        var str =   "";
        var chknum= 0
        for(var j=0; j<chkBin.length; j++){
            if(chkBin.item(j).type == "checkbox" && chkBin.item(j).checked){
                if(chknum>0) str += ",";
                str += chkBin.item(j).value;
                chknum++;
            }
        }
        if(str==""){
          alert("품목을 추가할 위치를 먼저 선택해주새요");
          return;      
        }else{
            this.resetAddForm();        
            this.add_bin_code = str;
            this.dialog_setLocationItem=true;
            this.uid_company = this.$store.getters.getUidCompany;
        }
    },


  }
};
</script>

<style>
.cell-header, .lotno-header, .item_code-header, .citem_name-header {
    border-right: 1px solid rgb(238, 235, 235);
    border-left: 1px solid rgb(238, 235, 235); 
    background: linear-gradient(to right, #ff6b6b, #556270); 
  }  

.v-data-table-header {
    background: linear-gradient(to right, #ff6b6b, #556270); 
  color: white; 
  }
.v-data-table__wrapper {
    height:72vh !important;
}
.v-card {
    white-space: nowrap;
  }
  .row-same-value {
    background-color: lightblue;
}
.custom-background-color {
    background-color: lightblue;
  }
  .gray-row {
    color: rgb(0, 0, 0);
    background-color: rgb(240, 250, 240);
}  
.bg-color-change {
    background-color: yellow;
  }
  .bg-gray {
    background-color: #f0f0f0; 
  }
  .bg-yellow {
    background-color: yellow; 
  }  
  tr.v-data-table__selected {
    color: rgb(33, 33, 32);
    background: #7d92f5 !important;
}  
</style>